import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    width: 150,
    fixed: "left",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("质检状态"),
    dataIndex: "status",
    key: "quality_inspection_status",
    width: 200,
    ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: T("批次"),
    width: 240,
    ellipsis: true,
    scopedSlots: { customRender: "batch" },
  },
  {
    title: T("数量"),
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: "total_quantity" },
  },
  {
    title: T("可用数量"),
    dataIndex: "available_quantity",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("单位"),
    dataIndex: "unit",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("总重量(吨)"),
    dataIndex: "weight",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "weight" },
  },
  {
    title: T("总体积(立方)"),
    dataIndex: "volume",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "volume" },
  },
  {
    title: T("规格"),
    dataIndex: "material_spec",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
    scopedSlots: { customRender: "remark" },
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 260,
    ellipsis: true,
    scopedSlots: { customRender: "action" },
  },
];
